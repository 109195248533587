// src/components/ChatBot.js
import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Input, 
  Button, 
  HStack, 
  Avatar, 
  Image, 
  useToast
} from '@chakra-ui/react';
import '../App.css';
import Header from '../header';
import { auth } from '../firebase';
import '../styles/chatContainer.css';
import '../chatBubble.css';
import { 
  getBirthDetails, 
  getChatLog, 
  getRasiandNavamsa, 
  storeChatLog, 
  getTimer, 
  addTwingenDetails
} from '../firebaseFunctions';
import LoaderImage from './LoaderImage';
import CardGrid from './CardGrid';
import botLogo from '../assets/icons/bot-img.png'

// Initial message from the system
const initialMessages = [];

const ChatBubble = () => {
  return (
    <Box className="chat-bubble">
      <Box className="typing">
        <Box className="dot"></Box>
        <Box className="dot"></Box>
        <Box className="dot"></Box>
      </Box>
    </Box>
  );
};

const ChatBot = () => {
  const [userInput, setUserInput] = useState('');
  const [chatLog, setChatLog] = useState(initialMessages);
  const [loading, setLoading] = useState(false); // Loading state to track API response
  const [timerStart, setTimerStart] = useState(false);
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [selectedTopic, setSelectedTopic] = useState(''); // State for selected topic
  const chatContainerRef = useRef(null);
  const toast = useToast();

  // Scroll to bottom of the chat when new messages are added
  useEffect(() => {
    getStoredChatLog();
    handleChatEnable();
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
    // eslint-disable-next-line
  }, []);

  const basicPredictions = async (requestBody) => {
    const response = await fetch('https://api-chat.guruji.chat/AstroGuruji/basic/life-prediction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    return await response.json();
  };

  const handleStoreChatLog = (updatedChatLog) => {
    try {
      const userId = auth.currentUser.uid; // Get the current user's ID

      console.log('Updated chatlog', updatedChatLog);
      // Call the FastAPI endpoint to store chat log
      const response = storeChatLog(userId, updatedChatLog);

      response.then((result) => {
        // Ensure description is a string
        const description = typeof result === 'string' ? result : (result.message || JSON.stringify(result));
        console.log('Chat stored successfully');
      });

      const twingenAgent = addTwingenDetails(userId, updatedChatLog);
      twingenAgent.then((result) => {
        console.log('Twingen details stored successfully');
        toast({
          title: 'Success',
          description: 'Twingen Agent updated successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      });
    } catch (error) {
      console.error('Error storing chat log:', error);
      toast({
        title: 'Error',
        description: 'An unexpected error occurred. Recent chat may not be not be stored',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getStoredChatLog = () => {
    try {
      const userId = auth.currentUser.uid; // Get the current user's ID

      // Call the FastAPI endpoint to get the stored chat log
      const response = getChatLog(userId);

      response
        .then((data) => {
          console.log('Stored chat:', data);
          if (data['chatLog'] !== undefined && data['chatLog'].length !== 0) {
            console.log('Stored chat log if:', data['chatLog']);
            setChatLog(data['chatLog']); // Set chat log from the response
          }
        })
        .catch((error) => {
          console.error('Error retrieving chat log:', error);
          toast({
            title: 'Error',
            description: "Internal error in restoring previous session!",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      console.error('Error retrieving chat log:', error);
      toast({
        title: 'Error',
        description: "Sorry please try again.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChatEnable = () => {
    try {
      const gettimer = getTimer(auth.currentUser.uid);
      gettimer
        .then((data) => {
          const dataTimer = data;
          const active_status = dataTimer.active_status;
          setIsChatEnabled(active_status);
        })
        .catch((error) => {
          console.error('Error retrieving chat log:', error);
          toast({
            title: 'Error',
            description: "Error in retrieving timer details.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handler for card clicks
  const handleCardClick = async (description, topic) => {
    const cardTopic = topic;

    setTimerStart(true);
  
    if (cardTopic === 'basic-predictions') {
      // Fetch birth predictions using basicPredictions API
      try {
        setLoading(true); // Start loading state

        const userMessage = { role: 'user', text: description };
  
        // Update chatLog state with user message and store the updated log
        setChatLog((prevChatLog) => {
          const updatedChatLog = [...prevChatLog, userMessage];
          handleStoreChatLog(updatedChatLog);
          return updatedChatLog;
        });
  
        // Retrieve user's birth details and Rasi/Navamsa data
        const birthDetails = await getBirthDetails(auth.currentUser.uid);
        const rasiNavamsaData = await getRasiandNavamsa();
  
        // Destructure necessary fields from birthDetails
        const { name, gender, age, ...rest } = birthDetails;

        console.log('REST:', rest)
  
        // Prepare request body for basicPredictions API
        const requestBody = { name, gender, age, ...rasiNavamsaData };
  
        // Call basicPredictions API
        const response = await basicPredictions(requestBody);
  
        setLoading(false); // End loading state
  
        // Extract prediction text from API response
        const predictionText =
          typeof response.prediction === 'string'
            ? response.prediction
            : response.prediction.message || "Here are your birth chart predictions.";
  
        // Create bot message
        const botMessage = { role: 'system', text: predictionText };
  
        // Update chatLog state and store the updated log
        setChatLog((prevChatLog) => {
          const updatedChatLog = [...prevChatLog, botMessage];
          handleStoreChatLog(updatedChatLog);
          return updatedChatLog;
        });
      } catch (error) {
        console.error('Error fetching birth predictions:', error);
        setLoading(false); // End loading state in case of error
        toast({
          title: 'Error',
          description: 'Failed to fetch birth predictions.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      // For other cards, send the description to the chat API
      const userMessage = { role: 'user', text: description };
  
      // Update chatLog state with user message and store the updated log
      setChatLog((prevChatLog) => {
        const updatedChatLog = [...prevChatLog, userMessage];
        handleStoreChatLog(updatedChatLog);
        return updatedChatLog;
      });
  
      setLoading(true); // Start loading state (show typing indicator)
  
      try {
        setLoading(true); // Start API loading state
  
        // Retrieve user's birth details and Rasi/Navamsa data
        const birthDetails = await getBirthDetails(auth.currentUser.uid);
        const rasiNavamsaData = await getRasiandNavamsa();
  
        // Destructure necessary fields from birthDetails
        const { name, gender, age, ...rest } = birthDetails;
  
        const requestBody = rest;

        // Extract latitude and longitude if needed
        const { latitude, longitude, ...rest1 } = rest;

        console.log('REST1:', rest1)
  
        // Call chat API with the description
        const response = await fetch(`https://api-chat.guruji.chat/AstroGuruji/chat/prompt/${cardTopic}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        const data = await response.json();
  
        console.log('prompt resp', data);
  
        // Update requestBody with the response from the first API call
        const updatedRequestBody = {
          name,
          age,
          gender,
          rasi_chart: rasiNavamsaData['rasi_chart'],
          navamsa_chart: rasiNavamsaData['navamsa_chart'],
          topic: cardTopic,
          latitude,
          longitude,
          d_chart: data, // Ensure data.response exists
        };
  
        console.log('REQUEST BODY:', updatedRequestBody);
  
        // Call the second chat API endpoint with the updated request body
        const response1 = await fetch(`https://api-chat.guruji.chat/AstroGuruji/chat/requirement`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedRequestBody),
        });
  
        const data1 = await response1.json();
  
        console.log('requirement resp', data1.response);
  
        const chatref = data1['initial_chat_history'][2];
        chatref['content'] = description;
  
        const requestBody1 = { chat_history: [data1['initial_chat_history'][0], data1['initial_chat_history'][1], chatref] };
  
        const response2 = await fetch(`https://api-chat.guruji.chat/AstroGuruji/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody1)
        });
  
        const data2 = await response2.json();
  
        // Extract bot response text from the second API response
        const botResponseText =
          typeof data2.response === 'string'
            ? data2.response
            : data2.response || "I'm here to help!";
  
        // Create bot message
        const botMessage = { role: 'system', text: botResponseText };
  
        // Update chatLog state with bot message and store the updated log
        setChatLog((prevChatLog) => {
          const updatedChatLog = [...prevChatLog, botMessage];
          handleStoreChatLog(updatedChatLog);
          return updatedChatLog;
        });
  
        setLoading(false); // End loading state
        setLoading(false); // End API loading state
        setTimerStart(false);
      } catch (error) {
        console.error('Error communicating with chat API:', error);
  
        // Create error message
        const errorMessage = { role: 'system', text: 'There was an error processing your request.' };
  
        // Update chatLog state with error message and store the updated log
        setChatLog((prevChatLog) => {
          const updatedChatLog = [...prevChatLog, errorMessage];
          handleStoreChatLog(updatedChatLog);
          return updatedChatLog;
        });
  
        setLoading(false); // End loading state
        setLoading(false); // End API loading state
      }
    };
  };


  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;
    else if (!isChatEnabled) {
      toast({
        title: 'Error',
        description: 'Chat is disabled. Please pay to continue chatting.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Pass the selectedTopic and userInput to handleCardClick
      setLoading(true); // Start loading state (show typing indicator)
      await handleCardClick(userInput, selectedTopic);
      setLoading(false); // Stop loading state (hide typing indicator)
      setUserInput('');
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { role: 'system', text: 'There was an error processing your request.' };
      setChatLog((prevChatLog) => [...prevChatLog, errorMessage]);
      handleStoreChatLog([...chatLog, errorMessage]);
    } finally {
      setLoading(false); // Stop loading state (hide typing indicator)
    }
  };

  return (
    <>
      {/* Pass selectedTopic and setSelectedTopic to Header via props */}
      <Header 
        timerStart={timerStart} 
        page="chat" // Adjust this prop based on the current page
        selectedTopic={selectedTopic}
        setSelectedTopic={setSelectedTopic}
      />

        <Box className="chat-container" ref={chatContainerRef}>        
          {chatLog.length !== 0 ? (
            chatLog.map((message, index) => (
              <HStack key={index} className={`message ${message.role}`} spacing={4}>
                {message.role === 'system' && <Avatar size="sm" name="Bot" src={botLogo} />}
                <Box className={`message-content ${message.role}`} >
                  {message.role === 'system' ? (
                    // Ensure message.text is a string before setting inner HTML
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          typeof message.text === 'string'
                            ? message.text
                                .replace(/### (.+)/g, '<h3>$1</h3>') // Convert ### to <h3>
                                .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>') // Convert **text** to <strong>
                                .replace(/\n/g, '<br />') // Convert new lines to <br>
                            : JSON.stringify(message.text), // Fallback if not a string
                      }}
                    />
                  ) : (
                    <p>{typeof message.text === 'string' ? message.text : JSON.stringify(message.text)}</p>
                  )}
                </Box>
                {message.role === 'user' && (
                  <Avatar size="sm" name="You" src={auth.currentUser?.photoURL} />
                )}
              </HStack>
            ))
          ) : (
            <CardGrid onCardClick={handleCardClick} /> // Pass handleCardClick to CardGrid
          )}

          {loading && (
            <HStack className="message system" spacing={4}>
              <Avatar size="sm" name="Bot" />
              <ChatBubble /> {/* Show typing indicator when loading */}
            </HStack>
          )}
        </Box>
      <HStack className="message-box" spacing={0}>
        <Input
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          style={{
            borderRadius: '200px',
            borderColor: 'black',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
            borderRight: 'none',
          }} // Remove border radius for the input
          placeholder="Type your message here..."
          marginLeft={300}
          flex="1"
          isDisabled={loading || !isChatEnabled || selectedTopic === ''}
        />
        <Button
          onClick={handleSendMessage}
          isDisabled={loading || !isChatEnabled || selectedTopic === ''}
          backgroundColor="white"
          border="1px" // Add border to the button
          marginRight={300}
          style={{
            borderRadius: '200px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            borderColor: 'black',
            borderLeft: 'none',
          }} // Remove border radius for the button
          paddingX={4} // Adjust padding for aesthetics
        >
          <Image
            src="https://img.icons8.com/fluency-systems-regular/50/sent--v1.png" // Replace with your image URL
            alt="Send"
            boxSize="20px" // Adjust the size as needed
          />
        </Button>
      </HStack>
    </>
  );
};

export default ChatBot;
