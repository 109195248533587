import React, { useEffect, useState } from 'react';
import { Button, Input, VStack, Text, useToast, Box, Image } from '@chakra-ui/react';
import { createUserWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import '../App.css';
import Header from '../header';
import { auth, db } from '../firebase';
import googleIcon from '../assets/icons/google.png';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../firebaseFunctions';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
  navigate('/signup');
    // eslint-disable-next-line
  }, []);

  const handleEmailSignUp = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async () => {
        console.log('Account created successfully.');
        toast({
            title: 'Success',
            description: 'Account created successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

        sendEmailVerification(auth.currentUser)
            .then(() => {
                console.log('Verification email sent successfully.');
                toast({
                    title: 'Success',
                    description: 'Verification email sent successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
            })
            .catch((error) => {
                console.error('Error sending verification email:', error.message);
                toast({
                    title: 'Error',
                    description: 'Error sending verification email.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
            });
      })
      .catch((error) => {
        console.error('Error during account creation:', error.message);
        toast({
            title: 'Error',
            description: 'Error during account creation.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
      });
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user exists in Firestore
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if(!userSnap.exists()){
      console.log('Signed up with Google successfully.');
      navigate('/birthdetails'); // Redirect to home page
      const response = createUser(auth.currentUser.uid, auth.currentUser);
      response.then((result) => {
        toast({
            title: 'Success',
            description: "User signed up successfully.",
            status: 'success',
            duration: 5000,
            isClosable: true
        })
      });
    }
    else {
      toast({
        title: 'Error',
        description: "User already exists. Please sign in.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    } catch (error) {
      console.error('Error during Google sign-in:', error.message);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <Header page='Sign In'/>
      <Box p={4} bg="white" borderRadius="md" boxShadow="md" maxWidth="400px" mx="auto" mt={10} style={{ marginTop: '3rem' }}
    >
      <VStack spacing={4} align="stretch">
        <Text fontSize="2xl" fontWeight="bold" textAlign="center">
          Create an Account
        </Text>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="filled"
          bg="gray.100"
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="filled"
          bg="gray.100"
        />
        <Button onClick={handleEmailSignUp} style={{ color: 'white', backgroundColor: 'black', borderRadius: '20px' }}>
          Sign Up with Email
        </Button>
        <Text textAlign="center">OR</Text>
          <Button onClick={handleGoogleSignUp} style={{ color:'black', backgroundColor: 'white', border: '1px solid black', borderRadius: '20px' }}>
          <Image src={googleIcon} alt="phone" width="20px" height="20px" marginRight='20px' />
            Sign Up with Google</Button>
        <Text textAlign="center">
          Already have an account?{' '}
          <Button variant="link" onClick={() => navigate('/signin')}>
            Sign In
          </Button>
        </Text>
      </VStack>
      </Box>
    </div>
  );
};

export default SignUp;
