import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { auth } from "./firebase";
import axios from 'axios';

// Your Firebase configuration (replace with your actual config)
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Function to create a user
export const createUser = async (userId = auth.currentUser.uid, currentUser = auth.currentUser) => {
  try {
    console.log('currentUser:', auth.currentUser.uid);
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    
    if (!userSnapshot.exists()) {
      const temp = {
        user_details: {
          user_name: currentUser.displayName,
          email: currentUser.email,
        },
        payment_details: {
          subscription: 'Free',
          payment_id: '',
          payment_status: 'Pending',
        },
        timer_details: {
          remaining_time: '60:00',
          active_status: true,
        },
        rasi_chart: {},
        navamsa_chart: {},
        birth_details: {},
        twingen_details: {},  // Added key for Twingen details
        chatLog: [],          // Initialize chatLog as an empty array
      };
      await setDoc(userRef, temp);
      return temp;
    } else {
      throw new Error("User already exists.");
    }
  } catch (error) {
    throw new Error(`Error creating user: ${error.message}`);
  }
};

// Function to store chat log
export const storeChatLog = async (userId = auth.currentUser.uid, chatLog) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      chatLog: chatLog,
    });
    return { message: "Chat log stored successfully." };
  } catch (error) {
    throw new Error(`Error storing chat log: ${error.message}`);
  }
};

// Function to get chat log
export const getChatLog = async (userId = auth.currentUser.uid) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      console.log('userSnapshot:', userSnapshot.data());
      return userSnapshot.data();
    } else {
      throw new Error("Chat log does not exist.");
    }
  } catch (error) {
    throw new Error(`Error retrieving chat log: ${error.message}`);
  }
};

// Function to update timer
export const updateTimer = async (userId = auth.currentUser.uid, remainingTime, activeStatus) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      timer_details: {
        remaining_time: remainingTime,
        active_status: activeStatus,
      },
    });
    return { message: "Timer updated successfully." };
  } catch (error) {
    throw new Error(`Error updating timer: ${error.message}`);
  }
};

// Function to get timer
export const getTimer = async (userId = auth.currentUser.uid) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      console.log('userSnapshot:', userSnapshot.data().timer_details);
      return userSnapshot.data().timer_details;
    } else {
      throw new Error("Timer does not exist.");
    }
  } catch (error) {
    throw new Error(`Error retrieving timer: ${error.message}`);
  }
};

// Function to update payment
export const updatePayment = async (userId = auth.currentUser.uid, paymentId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const tempref = {
      payment_details: {
        subscription: 'Paid',
        payment_id: paymentId,
        payment_status: 'Paid',
      },
      timer_details: {
        remaining_time: '60:00',
        active_status: true,
      },
    };
    await updateDoc(userRef, tempref);
    return tempref;
  } catch (error) {
    throw new Error(`Error updating payment details: ${error.message}`);
  }
};

// Function to update Rasi
export const updateRasi = async (userId = auth.currentUser.uid, rasi_chart) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      rasi_chart: rasi_chart,
    });
    return { message: "Rasi details updated successfully." };
  } catch (error) {
    throw new Error(`Error updating rasi_chart details: ${error.message}`);
  }
};

// Function to update Navamsa
export const updateNavamsa = async (userId = auth.currentUser.uid, navamsa_chart) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      navamsa_chart: navamsa_chart,
    });
    return { message: "Navamsa details updated successfully." };
  } catch (error) {
    throw new Error(`Error updating navamsa_chart details: ${error.message}`);
  }
};

// Function to get Rasi and Navamsa details
export const getRasiandNavamsa = async (userId = auth.currentUser.uid) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      return { 
        rasi_chart: userSnapshot.data().rasi_chart, 
        navamsa_chart: userSnapshot.data().navamsa_chart 
      };
    } else {
      throw new Error("Rasi and Navamsa details do not exist.");
    }
  } catch (error) {
    throw new Error(`Error fetching Rasi and Navamsa: ${error.message}`);
  }
};

// Function to update birth details
export const updateBirthDetails = async (userId = auth.currentUser.uid, birthDetails) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      birth_details: birthDetails,
    });
    return { message: "Birth details updated successfully." };
  } catch (error) {
    throw new Error(`Error updating birth details: ${error.message}`);
  }
};

// Function to get birth details
export const getBirthDetails = async (userId = auth.currentUser.uid) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      return userSnapshot.data().birth_details;
    } else {
      throw new Error("Birth details do not exist.");
    }
  } catch (error) {
    throw new Error(`Error retrieving birth details: ${error.message}`);
  }
};

// Function to add Twingen details
export const addTwingenDetails = async (userId = auth.currentUser.uid, messages) => {
  try {
    const endpoint = "https://twingenapi-bggtahasg9b2gqck.centralindia-01.azurewebsites.net/messages/rag/";
    
    // Prepare the payload as per your FastAPI endpoint's requirements
    const payload = {
      user_id: userId,
      messages: messages,
      api_key: process.env.REACT_APP_OPENAI_API_KEY,  // Ensure you have this key stored securely
    };
    
    // Send POST request to FastAPI endpoint
    const response = await axios.post(endpoint, payload);
    
    if (response.status === 200) {
      const twingenDetails = response.data.response;  // Adjust based on your FastAPI response structure
      
      // Update Firestore with Twingen details
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        twingen_details: twingenDetails,
      });
      
      return { message: "Twingen agent updated successfully.", data: twingenDetails };
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    throw new Error(`Error adding Twingen details: ${error.message}`);
  }
};
