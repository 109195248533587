import React from "react";
import "../styles/loaderImage.css" // Import the CSS file for animation
import rtImg from "../assets/06.svg";

const LoaderImage = () => {
  return (
    <div className="image-container">
      <img
        src={rtImg} // Replace with your image URL
        alt="Rotating"
        className="rotating-image"
      />
    </div>
  );
};

export default LoaderImage;
