// src/components/BirthDetails.js
import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Input,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  useToast,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  getBirthDetails,
  updateBirthDetails,
  updateNavamsa,
  updateRasi,
} from '../firebaseFunctions';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import LoaderImage from './LoaderImage';
import CitySelect from './CitySelect'; // Import the CitySelect component
import Header from '../header';
import axios from 'axios';

const BirthDetails = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    date: '',
    time: '',
    latitude: '',
    longitude: '',
    timezone: '',
    city: '', // Added city to formData
  });
  const [selectedCity, setSelectedCity] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle city selection from CitySelect component
  const handleCitySelect = (cityData) => {
    if (cityData) {
      setSelectedCity(cityData);
      console.log('CityData', cityData)
      setFormData({
        ...formData,
        latitude: cityData.latitude,
        longitude: cityData.longitude,
        city: cityData.name, // Set the city name
      });
    } else {
      setSelectedCity(null);
      setFormData({
        ...formData,
        latitude: '',
        longitude: '',
        city: '',
      });
    }
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  };

  const fetchTimezone = async (latitude, longitude) => {
    try {
      setApiLoading(true);
      const timezoneResponse = await axios.get(
        'http://api.timezonedb.com/v2.1/get-time-zone',
        {
          params: {
            key: process.env.REACT_APP_TIMEZONEDB_API_KEY, // Ensure this env variable is set
            format: 'json',
            by: 'position',
            lat: latitude,
            lng: longitude,
          },
        }
      );
      setApiLoading(false);
      const gmtOffsetInSeconds = timezoneResponse.data.gmtOffset;
      const gmtOffsetInHours = gmtOffsetInSeconds / 3600; // Convert seconds to hours
      return gmtOffsetInHours.toFixed(1); // Return the offset with one decimal place
    } catch (error) {
      console.error('Error fetching timezone:', error);
      setApiLoading(false);
      return 'Unknown Timezone';
    }
  };

  useEffect(() => {
    const fetchBirthDetails = async () => {
      setApiLoading(true);
      try {
        // Fetch existing birth details
        const existingDetails = await getBirthDetails(auth.currentUser.uid);

        if (existingDetails) {
          // Format date as YYYY-MM-DD
          const formattedDate = `${existingDetails.year}-${String(existingDetails.month).padStart(2, '0')}-${String(existingDetails.date).padStart(2, '0')}`;

          // Format time as HH:MM:SS
          const formattedTime = `${String(existingDetails.hours).padStart(2, '0')}:${String(existingDetails.minutes).padStart(2, '0')}:${String(existingDetails.seconds).padStart(2, '0')}`;

          // Set the form data with formatted date, time, and city name
          setFormData({
            name: existingDetails.name || '',
            gender: existingDetails.gender || '',
            date: formattedDate || '',
            time: formattedTime || '',
            latitude: existingDetails.latitude || '',
            longitude: existingDetails.longitude || '',
            timezone: existingDetails.timezone || '',
            city: '',
          });
        }
      } catch (error) {
        console.error('Error fetching birth details:', error);
        toast({
          title: 'Error',
          description: 'Unable to fetch existing birth details.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setApiLoading(false);
    };

    fetchBirthDetails();
    // eslint-disable-next-line
  }, []);

  // Function to handle form submission
  const handleSubmit = async () => {
    if (
      !formData.name ||
      !formData.gender ||
      !formData.date ||
      !formData.time ||
      !formData.latitude ||
      !formData.longitude
    ) {
      console.log('FormData:', formData);
      toast({
        title: 'Incomplete Data',
        description: 'Please fill in all the fields and select your city.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Combine selected date and time into a Date object
    const [year, month, day] = formData.date.split('-').map(Number);
    const [hours, minutes, seconds] = formData.time.split(':').map(Number);
    const combinedDateTime = new Date(year, month - 1, day, hours, minutes, seconds);

    if (isNaN(combinedDateTime.getTime())) {
      toast({
        title: 'Invalid Date or Time',
        description: 'Please ensure the date and time are correct.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const age = calculateAge(combinedDateTime);
    const timezone = await fetchTimezone(formData.latitude, formData.longitude);

    const requestBody = {
      ...formData,
      year: combinedDateTime.getFullYear(),
      month: combinedDateTime.getMonth() + 1, // Months are zero-indexed
      date: combinedDateTime.getDate(),
      hours: combinedDateTime.getHours(),
      minutes: combinedDateTime.getMinutes(),
      seconds: combinedDateTime.getSeconds(),
      age,
      timezone,
    };

    try {
      setApiLoading(true);
      const updateResponse = await updateBirthDetails(auth.currentUser.uid, requestBody);
      
      console.log(updateResponse.message); // Log the success message

      const { name, gender, age, ...astroData } = requestBody;

      // Fetch Rasi and Navamsa data
      const rasiResponse = await fetch('https://api-chat.guruji.chat/AstroGuruji/chart/rasi', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(astroData),
      });
      const rasiData = await rasiResponse.json();

      const navamsaResponse = await fetch(
        'https://api-chat.guruji.chat/AstroGuruji/chart/navamsa',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(astroData),
        }
      );
      const navamsaData = await navamsaResponse.json();

      if (rasiData && navamsaData) {
        await updateRasi(auth.currentUser.uid, rasiData);
        await updateNavamsa(auth.currentUser.uid, navamsaData);
      }

      setApiLoading(false);
      navigate('/chat');
      toast({
        title: 'Success',
        description: 'Birth details submitted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error submitting details:', error);
      setApiLoading(false);
      toast({
        title: 'Submission Error',
        description: 'There was an error submitting your details.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider>
      <Box
        bg="gray.100"
        minHeight="100vh"
        p={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg="white"
          p={6}
          marginTop="4rem"
          borderRadius="lg"
          shadow="lg"
          maxW="800px"
          w="100%"
        >
          <Header />
          {!apiLoading ? (
            <>
              <Heading as="h2" size="lg" textAlign="center" mb={6}>
                Enter Your Birth Details
              </Heading>
              <VStack spacing={4} align="stretch">
                {/* Name Field */}
                <FormControl isRequired isInvalid={!formData.name}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    placeholder="Enter your name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>Name is required.</FormErrorMessage>
                </FormControl>

                {/* Gender Field */}
                <FormControl isRequired isInvalid={!formData.gender}>
                  <FormLabel>Gender</FormLabel>
                  <Select
                    placeholder="Select gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                  <FormErrorMessage>Gender is required.</FormErrorMessage>
                </FormControl>

                {/* Date of Birth */}
                <FormControl isRequired isInvalid={!formData.date}>
                  <FormLabel>Date of Birth</FormLabel>
                  <Input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>Date is required.</FormErrorMessage>
                </FormControl>

                {/* Time of Birth */}
                <FormControl isRequired isInvalid={!formData.time}>
                  <FormLabel>Time of Birth</FormLabel>
                  <Input
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    step="1" // Allows seconds input
                  />
                  <FormErrorMessage>Time is required.</FormErrorMessage>
                </FormControl>

                {/* City Selection */}
                <FormControl isRequired isInvalid={!selectedCity}>
                  <FormLabel>City</FormLabel>
                  <CitySelect onCitySelect={handleCitySelect} selectedCity={selectedCity} />
                  {!selectedCity && (
                    <FormErrorMessage>City selection is required.</FormErrorMessage>
                  )}
                </FormControl>

                {/* Display Selected City Details */}
                {selectedCity && selectedCity.city && (
                  <Box p={4} bg="gray.50" borderRadius="md">
                    <Text><strong>Selected City:</strong> {selectedCity.city}</Text>
                    <Text><strong>Latitude:</strong> {selectedCity.latitude}</Text>
                    <Text><strong>Longitude:</strong> {selectedCity.longitude}</Text>
                  </Box>
                )}
              </VStack>

              <Button
                mt={6}
                colorScheme="teal"
                onClick={handleSubmit}
                isFullWidth
                isLoading={apiLoading}
                loadingText="Submitting..."
              >
                Submit
              </Button>
            </>
          ) : (
            <LoaderImage />
          )}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default BirthDetails;
