import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, Avatar, IconButton } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons'; // Import the EditIcon
import { auth } from '../firebase';
import Header from '../header';
import '../styles/profile.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import bgImage from '../assets/03.svg';
import { getChatLog } from '../firebaseFunctions';

const Profile = () => {
  const [user, setUser] = useState("");
  const [dob, setDob] = useState("");
  const [timeob, setTimeob] = useState("");
  const [sex, setSex] = useState("");
  const [userMail, setUserMail] = useState(''); // Initialize with the user email
  const [subscriptionStatus, setSubscriptionStatus] = useState(''); // Initialize with the subscription status
  const [paymentStatus, setPaymentStatus] = useState(''); // Initialize with the payment status
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/profile');
    const getDetails = () => {
      const response = getChatLog(auth.currentUser.uid);
      response.then((data) => {
      setUser(data['birth_details']['name']);
      setDob(`${data['birth_details']['date']} - ${data['birth_details']['month']} - ${data['birth_details']['year']}`);
      setTimeob(`${data['birth_details']['hours']} : ${data['birth_details']['minutes']} : ${data['birth_details']['seconds']}`);
      setSex(data['birth_details']['gender']);
      setUserMail(data['user_details']['email']);
      setSubscriptionStatus(data['payment_details']['subscription']);
      setPaymentStatus(data['payment_details']['payment_status']);
    });
  }
    getDetails();
// eslint-disable-next-line
  }, []);

  const handleEditClick = () => {
    navigate('/birthdetails'); // Navigate to the birth details page when edit icon is clicked
  };

  return (
    <div style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: "center", height: '80vh' }}>
      <Header />
      <Box className="profile-container">
        {user ? (
          <VStack spacing={4} align="center">
            <Avatar size="xl" name={user || 'User Name'} src={auth.currentUser.photoURL || ''} className="profile-avatar" />
            <IconButton
                aria-label="Edit Birth Details"
                icon={<EditIcon />}
                onClick={handleEditClick}
                ml={2} // Add some margin between the text and the icon
                size="sm"
              />
            <Text className="profile-name">{user}</Text>
            <Text className="profile-email">Email: {userMail}</Text>
            <Text className="profile-subscription">Subscription Status: {subscriptionStatus}</Text>
            <Text className="profile-payment">Payment Status: {paymentStatus}</Text>
            <Box display="flex" alignItems="center">
              <Text className="profile-dob">Date of Birth: {dob}</Text>
              {/* Edit icon next to Date of Birth */}
            </Box>
            <Text className="profile-timeob">Time of Birth: {timeob}</Text>
            <Text className="profile-sex">Sex: {sex}</Text>
          </VStack>
        ) : (
          <Text className="profile-loading">Loading user data...</Text>
        )}
      </Box>
    </div>
  );
};

export default Profile;
